/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */
@use '@angular/material' as mat;

.mat-mdc-form-field-infix:has(input.w-24) {
    width: 96px !important;
}

.mat-mdc-form-field-infix:has(input.w-26) {
    width: 104px !important;
}

.mat-mdc-form-field-infix:has(input.w-28) {
    width: 112px !important;
}

.mat-mdc-form-field-infix:has(input.w-30) {
    width: 120px !important;
}

.mat-mdc-form-field-infix:has(input.w-32) {
    width: 128px !important;
}

.mat-mdc-form-field-infix:has(input.w-34) {
    width: 136px !important;
}

.mat-mdc-form-field-infix:has(input.w-36) {
    width: 144px !important;
}

.mat-mdc-form-field-infix:has(input.w-54) {
    width: 216px !important;
}

.mat-mdc-form-field-infix:has(input.w-56) {
    width: 224px !important;
}

.mat-mdc-form-field-infix:has(input.w-60) {
    width: 240px !important;
}

.mat-mdc-form-field-infix:has(input.w-70) {
    width: 280px !important;
}

.mat-mdc-form-field-infix:has(input.max-w-80) {
    max-width: 320px !important;
}

.table-with-default-shadow {
    width: fit-content;
    @include mat.elevation(7);
}

.table-with-default-shadow th {
    white-space: nowrap;
}

td.centered {
    text-align: center;
}

td.no-wrap {
    white-space: nowrap;
}

.mat-mdc-paginator {
    .mat-mdc-paginator-container {
        display: flex;
        justify-content: flex-start;
        border-top: 1px solid currentColor;
    }
}

.mat-mdc-row .mat-mdc-cell {
    cursor: pointer;
}

.mat-mdc-row:hover .mat-mdc-cell {
    background: rgba(255, 255, 255, 0.1);
    border-color: currentColor;
}

.mat-mdc-row:nth-child(odd) {
    background: rgba(128, 128, 128, 0.05);
}

.mat-step-header {
    padding: 4px !important;
}

.mat-vertical-content {
    padding: 0 10px 10px 10px !important;
}

.mat-vertical-content-container {
    margin-left: 16px !important;
}

.mat-vertical-stepper-header {
    padding: calc((var(--mat-stepper-header-height) - 24px) / 2) 4px !important;
}

@media only screen and (min-width: 480px) {
    .mat-step-header {
        padding: 24px !important;
    }

    .mat-vertical-content {
        padding: 0 24px 24px 24px !important;
    }

    .mat-vertical-content-container {
        margin-left: 36px !important;
    }

    .mat-vertical-stepper-header {
        padding: calc((var(--mat-stepper-header-height) - 24px) / 2) 24px !important;
    }
}

@media (prefers-color-scheme: dark) {
    .mat-mdc-row:hover .mat-mdc-cell {
        background: rgba(0, 0, 0, 0.1);
        border-color: currentColor;
    }

    .mat-mdc-row:nth-child(odd) {
        background: rgba(128, 128, 128, 0.05);
    }
}

hr.header {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0.25rem;
    margin-bottom: 1rem;
    --tw-border-opacity: 1 !important;
    border-color: rgb(100 116 139 / var(--tw-border-opacity)) !important;
}

.mat-mdc-list-item {
    &.mdc-list-item--with-one-line {
        height: auto !important;
    }
}
