/* ----------------------------------------------------------------------------------------------------- */
/*  @ Main Tailwind file for injecting utilities.
/* ----------------------------------------------------------------------------------------------------- */
@tailwind utilities;

/* Additional height specifications */
.h-82 {
    height: 20.5rem /* 328px */ !important;
}
.h-84 {
    height: 21rem /* 336px */ !important;
}

/* Additional margin-top specifications */
.mt-1\.25 {
    margin-top: 0.3125rem /* 5px */ !important;
}

/* Additional max-width specifications */
.max-w-85 {
    max-width: 21.25rem /* 340px */ !important;
}
.max-w-122 {
    max-width: 30.5rem /* 488px */ !important;
}
.max-w-124 {
    max-width: 31rem /* 496px */ !important;
}
.max-w-126 {
    max-width: 31.5rem /* 504px */ !important;
}

/* Additional width specifications */
.w-19\.5 {
    width: 4.875rem /* 78px */ !important;
}
.w-23 {
    width: 5.75rem /* 92px */ !important;
}
.w-23\.5 {
    width: 5.875rem /* 94px */ !important;
}
.w-25 {
    width: 6.25rem /* 100px */ !important;
}
.w-27 {
    width: 6.75rem /* 108px */ !important;
}
.w-29 {
    width: 7.25rem /* 116px */ !important;
}
.w-82 {
    width: 20.5rem /* 328px */ !important;
}
.w-83 {
    width: 20.75rem /* 332px */ !important;
}
.w-84 {
    width: 21rem /* 336px */ !important;
}
.w-86 {
    width: 21.5rem /* 344px */ !important;
}
.w-88 {
    width: 22rem /* 352px */ !important;
}
.w-92 {
    width: 23rem /* 368px */ !important;
}
.w-93 {
    width: 23.25rem /* 372px */ !important;
}
.w-94 {
    width: 23.5rem /* 376px */ !important;
}
.w-96 {
    width: 24rem /* 384px */ !important;
}
.w-98 {
    width: 24.5rem /* 392px */ !important;
}
.w-106 {
    width: 26.5rem /* 424px */ !important;
}
.w-107 {
    width: 26.75rem /* 428px */ !important;
}
.w-108 {
    width: 27rem /* 432px */ !important;
}
.w-110 {
    width: 27.5rem /* 440px */ !important;
}
.w-112 {
    width: 28rem /* 448px */ !important;
}
.w-113 {
    width: 28.25rem /* 452px */ !important;
}
.w-114 {
    width: 28.5rem /* 456px */ !important;
}
.w-122 {
    width: 30.5rem /* 488px */ !important;
}
.w-124 {
    width: 31rem /* 496px */ !important;
}
